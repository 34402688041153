/*
main {
  margin-top: 360px;
}
*/

main .MuiPaper-root .MuiCardContent-root {
  padding-bottom: 16px;
}
.statusInfo {
  height: 34px;
  line-height: 34px;
  text-align: center;
  display: inline-block;
}
.executed {
  background-color: #41c07c;
}
.notStarted {
  background-color: #ff3939;
}
.onHold {
  background-color: #fdc229;
}
main .election {
  margin-left: -24px;
  margin-right: -24px;
}
.arrow {
  position: absolute;
  right: 0;
  bottom: 20px;
}
a {
  color: #fdc229;
}
@media (max-width: 600px) {
  main {
    margin-top: 420px;
  }
  main .MuiBox-root {
    flex-direction: column;
  }
}
